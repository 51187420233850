/*
COMMONS
 */
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Fira Code", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
	/*overflow: hidden;*/
}

a {
  color: inherit;
  text-decoration: none;
}

a:has(button) {
	width: fit-content;
}

* {
  box-sizing: border-box;
}

/*
FONTS
 */

/* Fire code */
@font-face {
	font-family: 'Fira Code';
	src: url('/fonts/fira-code/FiraCode-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url('/fonts/fira-code/FiraCode-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url('/fonts/fira-code/FiraCode-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url('/fonts/fira-code/FiraCode-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url('/fonts/fira-code/FiraCode-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code VF';
	src: url('/fonts/fira-code/FiraCode-VF.woff2') format('woff2-variations');
	/* font-weight requires a range: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Fonts/Variable_Fonts_Guide#Using_a_variable_font_font-face_changes */
	font-weight: 300 700;
	font-style: normal;
}

/* PP Neue Machina */
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapThin.woff2') format('woff2');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapUltralight.woff2') format('woff2');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapLight.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapRegular.woff2') format('woff2');
	font-weight: 375;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapMedium.woff2') format('woff2');
	font-weight: 450;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapSemibold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapBold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapUltrabold.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'PP Neue Machina';
	src: url('/fonts/pp-neue-machina/PPNeueMachina-InktrapBlack.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}
